.indicador-tarjeta {
    background-color: #2a85e6; /* Fondo primary */
    border-radius: 8px; /* Bordes redondeados */
    padding: 10px; /* Espaciado interno */
    color: white; /* Texto blanco */
    display: flex;
    flex-direction: column; /* Contenido en columnas */
    align-items: center;
    justify-content: center;
    width: auto; /* Ajusta al contenido */
    max-width: 300px; /* Tamaño máximo opcional */
    margin: auto; /* Centrado opcional */
    margin-bottom: 10px; /* Espaciado inferior */
}

.indicador-tarjeta2 {
    background-color: #f7442d; /* Fondo primary */
    border-radius: 8px; /* Bordes redondeados */
    padding: 10px; /* Espaciado interno */
    color: white; /* Texto blanco */
    display: flex;
    flex-direction: column; /* Contenido en columnas */
    align-items: center;
    justify-content: center;
    width: auto; /* Ajusta al contenido */
    max-width: 300px; /* Tamaño máximo opcional */
    margin: auto; /* Centrado opcional */
    margin-bottom: 10px; /* Espaciado inferior */
}


.indicador-tarjeta3 {
    background-color: #2ecc71; /* Fondo primary */
    border-radius: 8px; /* Bordes redondeados */
    padding: 10px; /* Espaciado interno */
    color: white; /* Texto blanco */
    display: flex;
    flex-direction: column; /* Contenido en columnas */
    align-items: center;
    justify-content: center;
    width: auto; /* Ajusta al contenido */
    max-width: 300px; /* Tamaño máximo opcional */
    margin: auto; /* Centrado opcional */
    margin-bottom: 10px; /* Espaciado inferior */
}

.indicador-contenedor {
    display: flex;
    align-items: center;
    width: 100%;
}

.indicador-icono {
    flex: 0 0 25%; /* Ocupa un 25% del ancho */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px; /* Tamaño del ícono */
    color: white; /* Ícono blanco */
}

.indicador-texto {
    flex: 1; /* Ocupa el resto del espacio */
    padding-left: 10px;
    text-align: left;
}

.indicador-texto p {
    margin: 0;
    font-weight: bold;
    font-size: 14px; /* Tamaño del texto */
}

.indicador-numero {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    background-color: #0960bdd0; /* Fondo más oscuro */
    padding: 5px 0;
    border-radius: 4px;
    font-weight: bold;
    font-size: 18px;
}



.indicador-numero2 {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    background-color: #bd1509d0; /* Fondo más oscuro */
    padding: 5px 0;
    border-radius: 4px;
    font-weight: bold;
    font-size: 18px;
}

.indicador-numero3 {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    background-color: #1d8348 ; /* Fondo más oscuro */
    padding: 5px 0;
    border-radius: 4px;
    font-weight: bold;
    font-size: 18px;
}



a {
    color: white; /* Cambia el color del texto a blanco */
    text-decoration: none; /* Elimina el subrayado de los enlaces */
}

a:hover {
    color: #ccc; /* Cambia el color cuando el mouse pasa sobre el enlace (opcional) */
}
